import React from 'react'
import { StyledNotFound } from './NotFound.styles'

const NotFound = () => {
  return (
    <StyledNotFound>
      <div className='err'>
        Page Not Found :&#41;
      </div>
    </StyledNotFound>
  )
}

export default NotFound