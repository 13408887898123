import { Popover } from "antd";
import {
  StyledNotificationPopover,
  StyledNotificationPopoverContent,
} from "./NotificationPopover.styles";
import { RiArrowRightSLine } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";
import {
  useClearNotificationsMutation,
  useGetNotificationsMutation,
  useReadNotificationMutation,
} from "../../../Services/sloan";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Notification } from "../../../Assets/Profile/Notification";

const NotificationPopover = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [total, setTotal] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [getNotifications] = useGetNotificationsMutation();
  const [readNotification] = useReadNotificationMutation();
  const [clearNotifications] = useClearNotificationsMutation();

  const fetchNotifications = async () => {
    const data = await getNotifications({ offset: notifications.length, limit: 10 }).unwrap();
    setNotifications([...notifications, ...data.notifications]);
    setTotal(data.total);
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const handleOpenChange = (open) => {
    setIsOpen(open);
  };

  const handleCrossClick = async (id) => {
    const filtered = notifications.filter((n) => n.id !== id);
    setNotifications(filtered);
    setTotal((total) => total - 1);
    await readNotification({ notification_id: id });
    console.log(id);
  };

  const handleClearAllClick = async () => {
    await clearNotifications().unwrap();
    setNotifications([]);
  };

  const content = (
    <StyledNotificationPopoverContent>
      <InfiniteScroll
        className="notification-box"
        dataLength={notifications.length}
        next={fetchNotifications}
        hasMore={notifications.length < total}
        loader={<h4>Loading...</h4>}
        height={500}
      >
        {notifications.map((notification) => (
          <div key={notification.id} className="notification">
            <div className="content">
              <h2>{notification.notification_title}</h2>
              <p>{notification.notification_message}</p>
            </div>
            <div className="button-group">
              <button className="icon-button">
                <RiArrowRightSLine />
              </button>
              <button className="icon-button" onClick={() => handleCrossClick(notification.id)}>
                <RxCross2 />
              </button>
            </div>
          </div>
        ))}
      </InfiniteScroll>
      <button className="button" onClick={handleClearAllClick}>
        Clear All
      </button>
    </StyledNotificationPopoverContent>
  );

  return (
    <StyledNotificationPopover
      onOpenChange={handleOpenChange}
      content={content}
      title="Notifications"
      overlayClassName="notification-popover"
      trigger="click"
    >
      <div>
        <Notification color={isOpen ? "#ff7f00" : "#fff"} />
        {!isOpen && total && <div className="count">{total}</div>}
      </div>
    </StyledNotificationPopover>
  );
};

export default NotificationPopover;
