import { toast } from 'react-toastify'
import { signTransaction } from 'sats-connect'

export const signWalletTransaction = async ({ psbtBase64, inputsToSign, network, setIsLoad }) => {
  return new Promise(async (resolve, reject) => {
    const signPsbtOptions = {
      payload: {
        network: {
          type: network
        },
        message: 'Sign Transaction',
        psbtBase64: psbtBase64,
        broadcast: false,
        inputsToSign
        // inputsToSign: [{
        //   address: address,
        //   signingIndexes: [index],
        // }],
      },
      onFinish: (response) => {
        resolve(response.psbtBase64); // Resolve the promise with the result
      },
      onCancel: () => {
        toast('Transaction Canceled'); // Reject the promise if canceled
        if (setIsLoad) {
          setIsLoad(false)
        }
      },
    };

    try {
      const response = await signTransaction(signPsbtOptions);
      console.log(response, 'resp');
    } catch (error) {
      console.log(error, "err");
      reject(error); // Reject the promise in case of an error
    }
  });
};