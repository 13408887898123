export const Notification = ({ color = '#fff' }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 36 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.171 28.6535L32.8308 24.8453C32.3394 23.9965 31.8948 22.3906 31.8948 21.45V15.6459C31.8948 10.2547 28.6654 5.59765 24.0085 3.41824C22.7916 1.30765 20.5451 0 17.9709 0C15.4202 0 13.1268 1.35353 11.9099 3.48706C7.34665 5.71235 4.18745 10.3235 4.18745 15.6459V21.45C4.18745 22.3906 3.74282 23.9965 3.25139 24.8224L0.887843 28.6535C-0.0482162 30.1906 -0.25883 31.8882 0.326207 33.4482C0.887842 34.9853 2.22173 36.1782 3.95343 36.7518C8.49332 38.2659 13.2672 39 18.0411 39C22.815 39 27.5889 38.2659 32.1288 36.7747C33.7669 36.2471 35.0306 35.0312 35.639 33.4482C36.2475 31.8653 36.0836 30.1218 35.171 28.6535Z"
        fill={color}
      />
      <path
        d="M25 41.025C23.9593 43.925 21.2088 46 17.9876 46C16.0301 46 14.0973 45.2 12.7345 43.775C11.9416 43.025 11.3469 42.025 11 41C11.3221 41.05 11.6442 41.075 11.9911 41.125C12.5611 41.2 13.1558 41.275 13.7504 41.325C15.1628 41.45 16.6 41.525 18.0372 41.525C19.4496 41.525 20.8619 41.45 22.2496 41.325C22.7699 41.275 23.2903 41.25 23.7858 41.175C24.1823 41.125 24.5788 41.075 25 41.025Z"
        fill={color}
      />
    </svg>
  );
};
