import { Button, Modal } from "antd";
import styled from "styled-components";

export const StyledRevokePopUp = styled(Modal)`
  .ant-modal-content {
    border-radius: 25px;
    background: rgba(0, 0, 0, 70%);
    padding: 0;
    display: flex;
    flex-direction: column;
  }
  .upper {
    padding: 60px 0px;
    text-align: center;
    color: #ff7f00;
    text-transform: uppercase;
    font-family: 'Avenir Bold';
    font-size: 32px;
  }
  .lower {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    h4 {
      color: #fff;
      text-align: center;
      font-family: 'Avenir Medium';
      font-size: 16px;
    }
    .image {
      top: -35px;
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      .img {
        height: 80px;
        width: 80px;
        border-radius: 15px;
        border: 2px solid #fff;
        object-fit: cover;
      }
      .title {
        color: #fff;
        padding: 0px 20px;
        text-align: center;
        text-transform: uppercase;
        font-family: 'Avenir Bold';
        font-size: 24px;
      }
    }
    .data {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-top: 140px;
      .sub-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 6px;
        h3 {
          margin: 0;
          padding: 0;
          color: #fff;
          text-transform: uppercase;
          font-family: 'Avenir Bold';
          font-size: 14px;
        }
        p {
          margin: 0;
          padding: 0;
          color: #ff7f00;
          text-align: center;
          font-family: 'Avenir Bold';
          font-size: 24px;
        }
      }
    }
    .ago {
      width: 100%;
      margin: 10px 0px;
      text-align: end;
      color: #fff;
      font-family: 'Avenir Medium';
      font-size: 16px;
    }
    .status {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 10px;
      h2 {
        margin: 0;
        color: #fff;
        text-align: center;
        font-family: 'Avenir Bold';
        font-size: 16px;
        text-transform: uppercase;
      }
      p {
        margin: 0;
        color: #fff;
        text-align: center;
        font-family: 'Avenir Medium';
        font-size: 24px;
      }
    }
    .interest {
      width: 100%;
      text-align: end;
      color: #707070;
      font-family: 'Avenir Medium';
      font-size: 16px;
    }
    .btns {
      display: flex;
      justify-content: center;
      gap: 20px;
      width: 100%;
      padding: 30px 0px;
      @media (max-width: 550px) {
        flex-direction: column;
      }
    }
  }
`;

export const StyledButton = styled(Button)`
  border: none;
  border-radius: 20px;
  width: 100%;
  min-width: 200px;
  color: #fff;
  background: #ff7f00;
  height: 64px;
  text-align: center;
  font-family: 'Avenir Bold';
  font-size: 24px;
  padding: 16px;
  cursor: pointer;
  &:hover {
    color: #fff !important;
    background-color: #901fe9;
  }
`;

// export const StyledBtn2 = styled(Button)`
//   border-radius: 20px;
//   background: #ff7f00;
//   min-width: 200px;
//   width: 100%;
//   height: 64px;
//   color: #fff;
//   border: none;
//   font-family: 'Avenir Bold';
//   font-size: 24px;
//   padding: 16px;
//   &:hover {
//     color: #fff !important;
//     background-color: #901fe9;
//   }
// `;
