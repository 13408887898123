import React from 'react'
import { Spin } from 'antd';
import { StyledLoading } from './Loader.styles';

const Loader = () => {
  return (
    <StyledLoading>
      <div className='load'>
        <Spin size="large"/>
      </div>
    </StyledLoading>

  )
}

export default Loader