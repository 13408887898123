import { Popover } from "antd";
import { styled } from "styled-components";

export const StyledNotificationPopover = styled(Popover)`
  position: relative;
  .count {
    color: #fff;
    background-color: #ff7f00;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    font-family: "Avenir Bold";
    font-size: 8px;
  }
`;

export const StyledNotificationPopoverContent = styled.div`
  width: 500px;
  font-family: "Avenir Bold";
  .notification-box {
    padding: 0 25px 25px 25px;
    max-height: 500px;
    overflow: scroll;
    .notification {
      padding: 20px 10px;
      border-bottom: 1px solid #fff;
      display: flex;
      justify-content: space-between;
      gap: 30px;
      .content {
        h2 {
          margin: 0;
          text-transform: uppercase;
          color: #ff7f00;
        }
        p {
          margin: 0;
          font-family: "Avenir Medium";
          color: #fff;
          font-size: 16px;
        }
      }
      .button-group {
        display: flex;
        gap: 10px;
        align-items: center;
        .icon-button {
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 32px;
          height: 32px;
          border: 2px solid #fff;
          border-radius: 50%;
          cursor: pointer;
          outline: none;
          background-color: transparent;
          color: #fff;
          font-size: 25px;
          &:hover {
            color: #ff7f00;
            border-color: #ff7f00;
          }
        }
      }
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        background-color: #ffffff30;
      }
    }
  }
  .button {
    width: 100%;
    padding: 20px;
    font-family: "Avenir Bold";
    font-size: 20px;
    color: #fff;
    background: #ff7f00;
    cursor: pointer;
    border: none;
    outline: none;
    &:hover {
      background-color: #901fe9;
    }
  }
`;
