import React, { useState } from "react";
import { StyledAdmin } from "./Admin.styles";
import { useForm } from "antd/es/form/Form";
import { Form, Input } from "antd";
import { StyledButton } from "../../Components/Modals/RevokeModal/RevokeModal.styles";
import { BsDiscord, BsGlobe, BsTwitter } from "react-icons/bs";
import {
  useCreateCollectionMutation,
  useLazyGetCollectionDataApiQuery,
} from "../../Services/sloan";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import WhiteListPopUp from "../../Components/Modals/WhiteList/WhiteList";
import { useSelector } from "react-redux";
import TickerDataMarquee from "../../Components/Marquee/TickerDataMarquee/TickerDataMarquee";

const Admin = () => {
  const navigate = useNavigate();
  const [createForm] = useForm();
  const addresses = useSelector((state) => state.global.addresses);
  const [symbol, setSymbol] = useState("");
  const [collectionData, { data, isLoading: dataLoading }] = useLazyGetCollectionDataApiQuery();
  const [create, { isLoading: CreateLoading }] = useCreateCollectionMutation();
  const [isWhitelistOpen, setIsWhiteListOpen] = useState(false);

  const closeWhiteList = () => {
    setIsWhiteListOpen(false);
  };

  const onSubmit = async (values) => {
    const apiData = {
      ...values,
      image_uri: data?.imageURI,
    };
    try {
      const res = await create(apiData).unwrap();
      toast(res?.message);
      createForm.resetFields();
      navigate("/lend");
    } catch (error) {
      console.log(error);
    }
  };

  const getCollectionData = async () => {
    try {
      if (symbol.length > 0) {
        await collectionData({ symbol: symbol }).unwrap();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <StyledAdmin>
        <TickerDataMarquee />

        <div className="top-btn">
          <StyledButton onClick={() => setIsWhiteListOpen(true)}>Add User to WhiteList</StyledButton>
        </div>

        <div className="create-collection">
          <Form form={createForm} onFinish={onSubmit} className="form" name="borrow-form">
            <Form.Item
              name="collection_name"
              rules={[
                {
                  required: true,
                  message: "Please input Collection Symbol",
                },
              ]}
            >
              <div className="field-container">
                <label className="label">Collection Symbol</label>
                <Input
                  onChange={(e) => setSymbol(e.target.value)}
                  placeholder="Enter collection symbol"
                  className="modal-input"
                />
              </div>
            </Form.Item>
            <Form.Item
              name="loan_duration_in_days"
              rules={[
                {
                  required: true,
                  message: "Please input Loan Duration",
                },
              ]}
            >
              <div className="field-container">
                <label className="label">Loan Duration</label>
                <Input placeholder="Enter Loan Duration (in Days)" className="modal-input" />
              </div>
            </Form.Item>
            <Form.Item
              name="apy"
              rules={[
                {
                  required: true,
                  message: "Please input Loan APY",
                },
              ]}
            >
              <div className="field-container">
                <label className="label">APY</label>
                <Input placeholder="Enter APY" className="modal-input" />
              </div>
            </Form.Item>
            <div className="btns">
              <StyledButton loading={dataLoading} onClick={getCollectionData}>
                Preview
              </StyledButton>
              {data && (
                <StyledButton loading={CreateLoading} htmlType="submit">
                  Continue
                </StyledButton>
              )}
            </div>
          </Form>
          {data && (
            <div className="preview">
              <div className="upper">
                <img src={data?.imageURI} alt="" />
                <div className="desc">{data?.description}</div>
              </div>
              <div className="lower">
                <div className="link">
                  <a href={data?.twitterLink} target="_blank">
                    <BsTwitter style={{ height: "40px", width: "40px", color: "#f90" }} />
                  </a>
                  <a href={data?.discordLink} target="_blank">
                    <BsDiscord style={{ height: "40px", width: "40px", color: "#f90" }} />
                  </a>
                  <a href={data?.websiteLink} target="_blank">
                    <BsGlobe style={{ height: "40px", width: "40px", color: "#f90" }} />
                  </a>
                </div>
                <div className="data">
                  <div className="value">
                    <h3>Collection Name</h3>
                    <p>{data?.name}</p>
                  </div>
                  <div className="value">
                    <h3>Total Supply</h3>
                    <p>{data?.supply}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {data === null && <div className="no-preview">No Preview Available</div>}
        </div>
      </StyledAdmin>
      <WhiteListPopUp
        handleCancel={closeWhiteList}
        isModalOpen={isWhitelistOpen}
        addresses={addresses}
      />
    </>
  );
};

export default Admin;
