import { toast } from "react-toastify";
import { signMessage } from "sats-connect";

export const signWalletMessage = async ({ address, message }) => {
  return new Promise(async (resolve, reject) => {
    const signMessageOptions = {
      payload: {
        network: {
          type: "Testnet",
        },
        address: address,
        message: message,
      },
      onFinish: (response) => {
        resolve(response);
      },
      onCancel: () => {
        toast("Signing Canceled");
      },
    };
    try {
      await signMessage(signMessageOptions);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};
