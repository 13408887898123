import { styled } from "styled-components";

export const StyledCountDownRange = styled.div`
  .range {
    margin: auto;
    margin-top: 10px;
    width: 80%;
    height: 10px;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    .progress {
      height: 100%;
      background-color: #ff7f00;
    }
  }
`;

export default StyledCountDownRange;
