import { Pagination } from "antd";
import { StyledPagination } from "./CustomPagination.styles";

const CustomPagination = ({ total, setFilters, filters }) => {
  function onChange(page) {
    setFilters((prev) => {
      return {
        ...prev,
        currentPage: page,
      };
    });
  }

  return (
    <StyledPagination>
      <Pagination pageSize={2} onChange={onChange} current={filters?.currentPage} total={total} />
    </StyledPagination>
  );
};
export default CustomPagination;
