import { Modal } from "antd";
import styled from "styled-components";

export const StyledExtendPopUp = styled(Modal)`
  .ant-modal-content {
    background: rgba(0, 0, 0, 70%);
    padding: 0;
    display: flex;
    flex-direction: column;
    border-radius: 25px;
  }
  .header {
    margin-top: 20px;
    margin-right: 20px;
    text-align: right;
    .cancel-btn {
      cursor: pointer;
    }
  }
  .upper {
    padding: 0 30px;
    .image {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      img {
        height: 80px;
        width: 80px;
        border-radius: 16px;
        object-fit: cover;
      }
      h3 {
        margin: 0;
        color: #fff;
        text-align: center;
        font-family: "Avenir Bold";
        font-size: 38px;
        font-style: normal;
        font-weight: 600;
        line-height: 125.8%; /* 60.384px */
        font-variant: all-small-caps;
      }
    }
  }
  .lower {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    h4 {
      color: #fff;
      text-align: center;
      font-family: "Avenir Bold";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 125.8%; /* 20.128px */
      letter-spacing: 0.32px;
    }
    .image {
      top: -35px;
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      .img {
        height: 80px;
        width: 80px;
        border-radius: 8px;
        border: 2px solid #fff;
        object-fit: cover;
      }
      .title {
        color: #000;
        padding: 0px 20px;
        text-align: center;
        text-transform: uppercase;
        font-family: "Avenir Bold";
        font-size: 24px;
        line-height: 125.8%; /* 30.192px */
        letter-spacing: 0.48px;
      }
    }
    .data {
      display: flex;
      width: 100%;
      justify-content: space-between;
      border-bottom: 1px solid #fff;
      padding-bottom: 10px;
      margin-bottom: 20px;
      .sub-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        /* gap: 6px; */
        h3 {
          text-transform: uppercase;
          margin: 0;
          padding: 0;
          color: #fff;
          font-family: "Avenir Bold";
          font-size: 12px;
        }
        p {
          margin: 0;
          padding: 0;
          color: #ff7f00;
          text-align: center;
          font-family: "Avenir Bold";
          font-size: 24px;
        }
      }
    }
    .ago {
      width: 100%;
      margin: 10px 0px;
      text-align: end;
      color: #fff;
      font-family: "Avenir Medium";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 111.3%; /* 17.808px */
      letter-spacing: 0.32px;
    }
    .status {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 10px;
      h2 {
        margin: 0;
        color: #fff;
        text-align: center;
        font-family: "Avenir Bold";
        font-size: 18px;
        font-style: normal;
        text-transform: uppercase;
      }
      p {
        margin: 0;
        color: #fff;
        text-align: center;
        font-family: "Avenir Medium";
        font-size: 24px;
      }
    }
    .elypsis {
      width: 200px;
      text-overflow: ellipsis;
    }
    .interest {
      width: 100%;
      text-align: end;
      color: #707070;
      font-family: "Avenir Medium";
      font-size: 16px;
    }
    .btns {
      display: flex;
      justify-content: center;
      gap: 20px;
      width: 100%;
      padding: 30px 0px;
      @media (max-width: 550px) {
        flex-direction: column;
      }
    }
  }
`;
