import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router";
import { ROUTES } from "../Constants/Routes";
import { PrivateRoute } from "./PrivateRoute";
import Loader from "../Components/Loader/Loader";
import NotFound from "../Components/NotFound/NotFound";
import ActiveLoans from "../Screens/ActiveLoans/ActiveLoans";
import Admin from "../Screens/Admin/Admin";
import { AdminRoute } from "./AdminRoutes";
const Home = lazy(() => import("../Screens/Home/Home"));
const Profile = lazy(() => import("../Screens/Profile/Profile"));
const Borrow = lazy(() => import("../Screens/Borrow/Borrow"));
const Lend = lazy(() => import("../Screens/Lend/Lend"));
const Loans = lazy(() => import("../Screens/Loans/Loans"));
const Offers = lazy(() => import("../Screens/Offers/Offers"));

export const AppRoutes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route element={<Home />} path={ROUTES.HOME} />
        {/* <Route element={<Listings />} path={ROUTES.LISTINGS} /> */}

        <Route element={<PrivateRoute />}>
          <Route element={<Lend />} path={ROUTES.Lend} />
          <Route element={<Profile />} path={ROUTES.PROFILE} />
          <Route element={<Loans />} path={ROUTES.LOAN} />
          <Route element={<Offers />} path={ROUTES.OFFER} />
          <Route element={<Borrow />} path={ROUTES.BORROW} />
          <Route element={<ActiveLoans />} path={ROUTES.ACTTIVE_LOANS} />
        </Route>
        <Route element={<AdminRoute />}>
          <Route element={<Admin />} path={ROUTES.ADMIN} />
        </Route>
        <Route element={<NotFound />} path="*" />
      </Routes>
    </Suspense>
  );
};
