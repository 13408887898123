import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addresses: [],
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setAddresses: (state, action) => {
      return {
        ...state,
        addresses: action.payload,
      };
    },
    setUserId: (state, action) => {
      return {
        ...state,
        userId: action.payload,
      };
    },
    setCmsData: (state, action) => {
      return {
        ...state,
        cmsData: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAddresses, setUserId, setCmsData } = globalSlice.actions;

export default globalSlice.reducer;
