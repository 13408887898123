import { useEffect } from "react";
import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import { AppRoutes } from "./Routes/AppRoutes";
import { checkIfLogin } from "./Utils/common";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./Components/Loader/Loader";
import Footer from "./Components/Footer/Footer";
import { useGetCmsDataQuery } from "./Services/cms";
import { setCmsData } from "./Services/globalslice";
import TopHeadline from "./Components/TopHeadline/TopHeadline";

function App() {
  const dispatch = useDispatch();
  const { data, isLoading } = useGetCmsDataQuery();

  // Checking if user is logged in or not and if login then updating the redux state.
  useEffect(() => {
    checkIfLogin({ dispatch });
  }, [dispatch]);

  useEffect(() => {
    dispatch(setCmsData(data?.data));
  }, [data, dispatch]);

  return (
    <>
      {isLoading && <Loader />}
      <div className="app">
        {/* <TopHeadline /> */}
        <Navbar />
        <AppRoutes />
        <ToastContainer
          className="toast-position"
          position="top-center"
          autoClose={1500}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          closeButton={false}
        />
        <Footer />
      </div>
    </>
  );
}

export default App;
