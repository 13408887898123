import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define a service using a base URL and expected endpoints
export const blockCypherApi = createApi({
  reducerPath: "blockCypherApi",
  baseQuery: fetchBaseQuery({ baseUrl: "https://api.blockcypher.com" }),
  endpoints: (builder) => ({
    getWalletDetails: builder.query({
      query: (data) => {
        return {
          url: `/v1/btc/test3/addrs/${data?.address}/full?limit=1`,
          method: "GET",
        };
      },
    }),
  }),
});

export const { useGetWalletDetailsQuery } = blockCypherApi;
