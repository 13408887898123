import React from 'react'
import { StyledTop } from './TopHeadline.styles'

const TopHeadline = () => {
  return (
    <StyledTop>
      Closed alpha access. funds can be lost.
    </StyledTop>
  )
}

export default TopHeadline