import React, { useState } from "react";
import { StyledButton, StyledRevokePopUp } from "./RevokeModal.styles";
import { Divider } from "antd";
import pfp from "../../../Assets/Images/pfp.png";
import { useRevokeExecuteMutation, useRevokeOffersMutation } from "../../../Services/sloan";
import { config } from "../../../config";
import { signWalletTransaction } from "../../../Utils/Sats-connect/SignTransaction";
import { useSelector } from "react-redux";
import moment from "moment";

const RevokeModal = ({ isModalOpen, handleOk, handleCancel, view, item, data }) => {
  const addresses = useSelector((state) => state.global.addresses);
  const [revoke, setRevoke] = useState(false);
  const [revokeOffer, { isLoading: revokeLoading }] = useRevokeOffersMutation();
  const [executeRevoke, { isLoading: executeSignatureLoading }] = useRevokeExecuteMutation();

  const handleRevoke = async () => {
    try {
      const revokeData = {
        offer_escrow_id: data?.offer_escrow_id,
        offer_creator_address: addresses[1]?.address,
        offer_creator_pubkey: addresses[1]?.publicKey,
      };
      const res = await revokeOffer(revokeData).unwrap();
      const newArr = res?.data?.executeEscrow?.sides
        .filter((item) => item?.movement === "input")
        .map((item, i) => {
          return {
            address: item?.leaf,
            signingIndexes: [i],
          };
        });
      const finalArr = newArr.filter((item) => item?.address !== null);
      const txn = await signWalletTransaction({
        psbtBase64: res?.data?.executeEscrow?.transactions[0]?.base64,
        // address: addresses[1]?.address,
        // index: 1,
        inputsToSign: finalArr,
        network: config.ENVIRONMENT,
      });
      const executeSignatureData = {
        base64: txn,
        escrow_id: data?.offer_escrow_id,
        collection_name: item?.collection_info?.collection_name,
      };
      await executeRevoke(executeSignatureData).unwrap();
      setRevoke(true);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <StyledRevokePopUp
      footer={null}
      closeIcon={false}
      title=""
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <div className="upper">{item?.collection_info?.collection_name}</div>
      <div className="lower">
        <div className="image">
          <img
            className="img"
            src={item?.collection_info?.image_uri ? item?.collection_info?.image_uri : pfp}
            alt=""
          />
          <div className="title">
            {!revoke
              ? `${item?.collection_info?.collection_name}`
              : `You have successfully revoked your offer on ${
                  item?.collection_info?.collection_name
                } for ${(data?.offer_amount / 10 ** 8).toFixed(8)} BTC`}
          </div>
        </div>
        <div className="data">
          <div className="sub-data">
            <h3>apy</h3>
            <p>{item?.collection_info?.apy}</p>
          </div>
          <div className="sub-data">
            <h3>duration</h3>
            <p>{item?.collection_info?.loan_duration_in_days}D</p>
          </div>
          {/* <div className="sub-data">
            <h3>floor</h3>
            <p>0.13</p>
          </div> */}
        </div>
        {/* <div className="ago">
          uploaded 10 days ago
        </div> */}
        <Divider />
        {view ? (
          <div className="status">
            <h2>Expires</h2>
            <p>{moment(data?.loan_end_date).format("DD-MM-YYYY")}</p>
          </div>
        ) : (
          <div className="status">
            <h2>status</h2>
            <p>Seeking Borrowers</p>
          </div>
        )}
        <div className="status">
          <h2>offer amount</h2>
          <p>{(data?.offer_amount / 10 ** 8).toFixed(8)}</p>
        </div>
        <div className="interest">{(data?.interest / 10 ** 8).toFixed(8)} BTC interest</div>
        <div className="btns">
          <StyledButton onClick={handleCancel}>Close</StyledButton>
          {!view && (
            <StyledButton
              disabled={revoke}
              loading={revokeLoading || executeSignatureLoading}
              onClick={handleRevoke}
            >
              Revoke Offer
            </StyledButton>
          )}
        </div>
      </div>
    </StyledRevokePopUp>
  );
};

export default RevokeModal;
