import styled from "styled-components";

export const StyledAdmin = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  padding-bottom: 80px;
  gap: 50px;
  .top-btn {
    width: 350px;
    align-self: self-end;
  }
  .create-collection {
    border-radius: 50px;
    padding: 50px 60px;
    height: 100%;
    background: rgba(0, 0, 0, 70%);
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 50%;
    .no-preview {
      padding: 40px;
      color: #000;
      text-align: center;
      text-transform: uppercase;
      font-family: "Avenir Bold";
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 125.8%; /* 30.192px */
      letter-spacing: 0.48px;
    }
    .form {
      .label {
        color: #fff;
        text-align: center;
        text-transform: uppercase;
        font-family: "Avenir Bold";
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 125.8%; /* 30.192px */
        letter-spacing: 0.48px;
      }
      .modal-input {
        margin-top: 7px;
        border-width: 2px;
        color: #fff;
        background: transparent;
        padding: 8px 12px;
        font-size: 16px;
        border-radius: 0%;
        &:hover,
        &:focus {
          border-color: #ff7f00;
        }
        &::placeholder {
          color: gray;
        }
      }
      .btns {
        display: flex;
        margin-top: 50px;
        gap: 16px;
        @media (max-width: 550px) {
          flex-direction: column;
        }
      }
    }
    .preview {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .upper {
        display: flex;
        gap: 16px;
        img {
          height: 240px;
          width: 240px;
          border-radius: 8px;
          border: 1px solid #9e9e9e;
          object-fit: cover;
        }
        .desc {
          height: 240px;
          overflow-y: scroll;
          color: #707070;
          text-align: center;
          font-family: "Avenir Medium";
          font-size: 20px;
        }
        @media (max-width: 550px) {
          flex-direction: column;
        }
      }
      .lower {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .link {
          display: flex;
          justify-content: center;
          gap: 30px;
          a {
            text-decoration: none;
            text-align: center;
            font-family: "Avenir Medium";
            font-size: 20px;
          }
        }
        .data {
          .value {
            display: flex;
            justify-content: space-between;
            h3 {
              margin: 0;
            }
            p {
              margin: 0;
              cursor: pointer;
              overflow: hidden;
              width: 240px;
              text-align: end;
              text-overflow: ellipsis;
              color: #707070;
              font-family: 'Avenir Medium';
              font-size: 20px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1050px) {
    padding: 40px;
    .create-collection {
      width: 80%;
    }
  }
  @media (max-width: 550px) {
    padding: 20px;
    .create-collection {
      padding: 20px;
      width: 80%;
    }
  }
`;
