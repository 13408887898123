import { styled } from "styled-components";

export const StyledTickerDataMarquee = styled.div`
  display: flex;
  border: 1px solid #fff;
  border-radius: 8px;
  padding: 8px 20px;
  color: #fff;
  font-family: 'Avenir Medium';
  font-size: 20px;
  span {
    margin-right: 50px;
  }
`;
