import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { config } from "./config";

export const rtkBaseQuery = fetchBaseQuery({
  baseUrl: config.BASE_URL,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem("token");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});
