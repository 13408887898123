import React, { useEffect, useMemo, useState } from "react";
import { useLazyGetOffersQuery } from "../../Services/sloan";
import { useSelector } from "react-redux";
import CustomPagination from "../../Components/CustomPagination/CustomPagination";
import Loader from "../../Components/Loader/Loader";
import OfferCard from "../../Components/OfferCard/OfferCard";
import { Collapse, Empty } from "antd";
import { StyledSearchInput } from "../../GlobalStyles";
import { StyledActiveLoans } from "./ActiveLoans.styles";
import debounce from "lodash.debounce";

const ActiveLoans = () => {
  const cms = useSelector((state) => state.global.cmsData);
  const userId = useSelector((state) => state.global.userId);
  const [filters, setFilters] = useState({
    currentPage: 1,
    limit: 6,
    address: userId,
    offer_type: "active",
  });
  const [getOffers, { data: activeLoans, isLoading: activeLoading }] = useLazyGetOffersQuery();

  const getData = async (page, searchText) => {
    try {
      await getOffers({
        currentPage: page,
        limit: 6,
        address: userId,
        offer_type: "active",
        search: searchText,
      }).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearchChange = (event) => {
    getData(1, event.target.value);
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleSearchChange, 3000);
  }, [handleSearchChange]);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  useEffect(() => {
    if (userId) {
      getData(filters?.currentPage, "");
    }
  }, [userId, filters]);

  useEffect(() => {
    setFilters((prev) => {
      return {
        ...prev,
        address: userId,
      };
    });
  }, [userId]);

  return (
    <StyledActiveLoans>
      {activeLoading && <Loader />}
      <div className="container">
        <div className="header">
          <h1>{cms?.attributes?.activeHeadline}</h1>
          <p>{cms?.attributes?.ActiveDescription}</p>
        </div>
        {/* <div className="details">
          <h3>Active Loan Details</h3>
          <div className="cards-container">
            <div className="card">
              <h2>total interest earned (All time)</h2>
              <h1>1.09</h1>
              <p>+0.25 expected from the active loans</p>
            </div>
            <div className="card">
              <h2>total interest earned (All time)</h2>
              <h1>1.09</h1>
              <p>13 active loans</p>
            </div>
            <div className="card">
              <h2>TOTAL Offer value</h2>
              <h1>1.09</h1>
              <p>13 open offers</p>
            </div>
            <div className="card">
              <h2>Foreclosure rate</h2>
              <h1>8.3%</h1>
              <p>626 foreclosed</p>
            </div>
            <div className="card">
              <h2>under water</h2>
              <h1>0 loans</h1>
              <p>0.00% of active loans</p>
            </div>
          </div>
        </div> */}
        <div className="search">
          <StyledSearchInput small onChange={debouncedResults} placeholder="Search Collection" />
          {/* <CSVLink className="csv" filename={"active-loans.csv"} data={dataaa} headers={headers}>
            <StyledDownload><HiDownload /> Download History</StyledDownload>
          </CSVLink> */}
        </div>
      </div>

      <div className="collapse-container">
        <div className="head">
          <div className="col">Collections</div>
          <div className="col">Offer</div>
          <div className="col">Interest</div>
          <div className="col">APY</div>
          <div className="col">Status</div>
          <div className="col"></div>
        </div>
        {activeLoans?.data && activeLoans?.data.length > 0 ? (
          activeLoans?.data?.map((item) => (
            <Collapse
              key={item?._id}
              destroyInactivePanel={true}
              expandIconPosition={"end"}
              bordered={false}
              items={[
                {
                  key: "1",
                  label: <OfferCard item={item} active={true} />,
                  children: item?.offers.map((data) => (
                    <OfferCard active={true} view={true} item={item} data={data} revoke={true} />
                  )),
                },
              ]}
            />
          ))
        ) : (
          <Empty description={"No Data Found"} />
        )}
      </div>
      <div className="pagination">
        <CustomPagination total={activeLoans?.total} setFilters={setFilters} filters={filters} />
      </div>
    </StyledActiveLoans>
  );
};

export default ActiveLoans;
