import { Button, Modal } from "antd";
import styled from "styled-components";

export const StyledWhiteListPopUp = styled(Modal)`
  .ant-modal-content {
    border-radius: 25px;
    background: rgba(0, 0, 0, 70%);
    padding: 40px;
    box-shadow: 8px 8px 15px 0px rgba(0, 0, 0, 0.25);
  }
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
  h1 {
    margin: 0px;
    color: #fff;
    text-align: center;
    font-family: "Avenir Bold";
    font-size: 32px;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 900;
    line-height: 113.3%;
  }
  p {
    margin: 10px;
    color: #fff;
    text-align: center;
    font-family: "Avenir Medium";
    font-size: 16px;
    line-height: 113.3%;
  }
  .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    .field-container {
      width: 100%;
    }
    .ant-form-item {
      width: 100%;
    }
    .label {
      color: #fff;
      text-align: center;
      text-transform: uppercase;
      font-family: "Avenir Bold";
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 125.8%; /* 30.192px */
      letter-spacing: 0.48px;
    }
    .modal-input {
      color: #fff;
      background: transparent;
      border-radius: 0;
      border-width: 2px;
      padding: 12px;
      font-size: 16px;
      &:hover,
      &:focus {
        border-color: #ff7f00;
      }
      &::placeholder {
        color: gray;
      }
    }
  }
  .btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
    .active {
      background: green;
      border-radius: 12px;
      color: #fff;
      font-family: "Avenir Medium";
      font-size: 16px;
      padding: 4px 10px;
    }
    .not-active {
      background: #b47f35;
      border-radius: 12px;
      color: #ffff;
      font-family: "Avenir Medium";
      font-size: 16px;
      padding: 4px 10px;
    }
  }
`;

export const StyledBtn = styled(Button)`
  border-radius: 20px;
  background: #ff7f00;
  min-width: 200px;
  width: 50%;
  height: 60px;
  color: #fff;
  border: none;
  font-family: "Avenir Bold";
  font-size: 18px;
  padding: 20px 30px;
  line-height: 125.8%; /* 22.644px */
  letter-spacing: 0.36px;
  &:hover {
    color: #fff !important;
    background-color: #901fe9;
  }
`;
