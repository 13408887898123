import styled from "styled-components";

export const StyledActiveLoans = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  gap: 60px;
  .pagination {
    width: 100%;
    margin-bottom: 60px;
  }
  .container {
    padding: 0px 80px;
    display: flex;
    flex-direction: column;
    gap: 60px;
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;
    h1 {
      margin: 0;
      color: #fff;
      text-align: center;
      font-family: "Avenir Bold";
      font-size: 64px;
    }
    p {
      color: #fff;
      text-align: center;
      font-family: "Avenir Medium";
      font-size: 24px;
    }
    .active {
      margin-top: 20px;
      border-radius: 11px;
      background: #f90;
      color: #fff;
      text-align: center;
      font-family: "Avenir Bold";
      font-size: 24px;
      padding: 10px 30px;
    }
  }
  .details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    h3 {
      margin: 0;
      padding: 0;
      text-align: start;
      color: #000;
      font-family: 'Avenir Bold';
      font-size: 32px;
    }
    .cards-container {
      display: flex;
      flex-direction: row;
      gap: 20px;
      .card {
        border-radius: 15px;
        border: 2px solid rgba(112, 112, 112, 0.28);
        background: #fff;
        padding: 24px 60px 24px 24px;
        /* min-width: 200px; */
        display: flex;
        flex-direction: column;
        gap: 12px;
        h2 {
          margin: 0;
          padding: 0;
          color: #000;
          font-family: 'Avenir Bold';
          font-size: 18px;
        }
        h1 {
          margin: 0;
          padding: 0;
          color: #f90;
          font-family: Roboto;
          font-size: 40px;
          font-style: normal;
          font-weight: 600;
          line-height: 125.8%; /* 50.32px */
          font-variant: all-small-caps;
          letter-spacing: 0.8px;
        }
        p {
          margin: 0;
          padding: 0;
          color: #707070;
          font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 125.8%; /* 22.644px */
          letter-spacing: 0.36px;
        }
      }
    }
  }
  .search {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .csv {
      text-decoration: none;
    }
  }
  .collapse-container {
    width: 100%;
    .ant-empty {
      margin-top: 60px;
    }
    .head {
      padding: 25px 60px;
      background-color: #ff7f00;
      display: grid;
      align-items: center;
      grid-template-columns: repeat(6, 1fr);
      .col {
        color: #fff;
        font-family: "Avenir Bold";
        text-align: center;
        font-size: 24px;
      }
    }
  }
  @media (max-width: 1050px) {
    .container {
      padding: 0;
    }
    .header {
      padding: 10px;
      padding-top: 80px;
      h1 {
        font-size: 40px;
      }
      p {
        font-size: 16px;
      }
    }
    .details {
      padding: 10px;
      .cards-container {
        flex-wrap: wrap;
      }
    }
    .search {
      flex-direction: column;
      padding: 0px 20px;
      gap: 16px;
    }
  }
  @media (max-width: 550px) {
    .details {
      .cards-container {
        .card {
          width: 100%;
        }
      }
    }
  }
  @media (max-width: 1280px) {
    align-items: flex-start;
    .collapse-container {
      width: 100%;
      overflow: scroll;
      .head {
        width: 1280px;
        padding: 25px 16px;
      }
    }
  }
`;
