export const LendIcon = ({ color }) => {
  return (
    <svg width="50" height="50" viewBox="0 0 41 40" fill={color} xmlns="http://www.w3.org/2000/svg">
      <g id="vuesax/bold/card-add">
        <g id="card-add">
          <path id="Vector" d="M3.8335 19.1002V27.4335C3.8335 31.2502 6.91683 34.3335 10.7335 34.3335H30.2502C34.0668 34.3335 37.1668 31.2335 37.1668 27.4168V19.1002C37.1668 17.9835 36.2668 17.0835 35.1502 17.0835H5.85016C4.7335 17.0835 3.8335 17.9835 3.8335 19.1002ZM13.8335 28.7502H10.5002C9.81683 28.7502 9.25016 28.1835 9.25016 27.5002C9.25016 26.8168 9.81683 26.2502 10.5002 26.2502H13.8335C14.5168 26.2502 15.0835 26.8168 15.0835 27.5002C15.0835 28.1835 14.5168 28.7502 13.8335 28.7502ZM24.6668 28.7502H18.0002C17.3168 28.7502 16.7502 28.1835 16.7502 27.5002C16.7502 26.8168 17.3168 26.2502 18.0002 26.2502H24.6668C25.3502 26.2502 25.9168 26.8168 25.9168 27.5002C25.9168 28.1835 25.3502 28.7502 24.6668 28.7502Z" fill={color} />
          <path id="Vector_2" d="M23.0002 7.68317V12.5665C23.0002 13.6832 22.1002 14.5832 20.9835 14.5832H5.85016C4.71683 14.5832 3.8335 13.6498 3.8335 12.5332C3.85016 10.6498 4.60016 8.93317 5.85016 7.68317C7.10016 6.43317 8.8335 5.6665 10.7335 5.6665H20.9835C22.1002 5.6665 23.0002 6.5665 23.0002 7.68317Z" fill={color} />
          <path id="Vector_3" d="M33.7833 3.3335H28.8833C26.7667 3.3335 25.5 4.60016 25.5 6.71683V11.6168C25.5 13.7335 26.7667 15.0002 28.8833 15.0002H33.7833C35.9 15.0002 37.1667 13.7335 37.1667 11.6168V6.71683C37.1667 4.60016 35.9 3.3335 33.7833 3.3335ZM35.35 9.8835C35.1833 10.0502 34.9333 10.1668 34.6667 10.1835H32.3167L32.3333 12.5002C32.3167 12.7835 32.2167 13.0168 32.0167 13.2168C31.85 13.3835 31.6 13.5002 31.3333 13.5002C30.7833 13.5002 30.3333 13.0502 30.3333 12.5002V10.1668L28 10.1835C27.45 10.1835 27 9.71683 27 9.16683C27 8.61683 27.45 8.16683 28 8.16683L30.3333 8.1835V5.85016C30.3333 5.30016 30.7833 4.8335 31.3333 4.8335C31.8833 4.8335 32.3333 5.30016 32.3333 5.85016L32.3167 8.16683H34.6667C35.2167 8.16683 35.6667 8.61683 35.6667 9.16683C35.65 9.45016 35.5333 9.6835 35.35 9.8835Z" fill={color} />
        </g>
      </g>
    </svg>
  );
};
