import { useTickerDataQuery } from "../../../Services/sloan";
import { StyledTickerDataMarquee } from "./TickerDataMarquee.styles";

const TickerDataMarquee = () => {
  const { data: tickerData } = useTickerDataQuery();

  return (
    <StyledTickerDataMarquee>
      <marquee>
        <span>Total Volume {(Number(tickerData?.total_volume) / 10 ** 8).toFixed(6)}BTC </span>
        <span>
          Available Liquidity {(Number(tickerData?.available_liquidity) / 10 ** 8).toFixed(6)}BTC
        </span>
        <span>Active Loans {tickerData?.active_loans}</span>
        <span>Default Rate {tickerData?.default_rate}</span>
      </marquee>
    </StyledTickerDataMarquee>
  );
};

export default TickerDataMarquee;
