import ms from "ms";
import { StyledCountDownRange } from "./CountDownRange.styles";
import useCurrentTime from "../../hooks/useCurrentTime";

const CountDownRange = ({ startTime, endTime }) => {
  const currentTime = useCurrentTime();
  const totalTime = endTime - startTime;
  const completedTime = currentTime - startTime;
  const completionPercentage = Math.ceil((completedTime * 100) / totalTime);
  const remainingTime = endTime - currentTime;

  return (
    <StyledCountDownRange>
      <div>{ms(remainingTime)} Remaining</div>
      <div className="range">
        <div className="progress" style={{ width: completionPercentage + "%" }}></div>
      </div>
    </StyledCountDownRange>
  );
};

export default CountDownRange;
