import { configureStore } from "@reduxjs/toolkit";
import globalReducer from "./Services/globalslice";
import { setupListeners } from "@reduxjs/toolkit/query";
import { hiroApi } from "./Services/hiro";
import { solanApi } from "./Services/sloan";
import { mempoolApi } from "./Services/mempool";
import { magicedenApi } from "./Services/magiceden";
import { blockCypherApi } from "./Services/blockCypher";
import { cmsApi } from "./Services/cms";

export const store = configureStore({
  reducer: {
    [hiroApi.reducerPath]: hiroApi.reducer,
    [solanApi.reducerPath]: solanApi.reducer,
    [mempoolApi.reducerPath]: mempoolApi.reducer,
    [magicedenApi.reducerPath]: magicedenApi.reducer,
    [blockCypherApi.reducerPath]: blockCypherApi.reducer,
    [cmsApi.reducerPath]: cmsApi.reducer,
    global: globalReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(hiroApi.middleware).concat(solanApi.middleware).concat(blockCypherApi.middleware).concat(mempoolApi.middleware).concat(magicedenApi.middleware).concat(cmsApi.middleware)
});
setupListeners(store.dispatch);
