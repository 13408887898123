import React from "react";
import { useState, useEffect } from "react";

const CountDown = (startTime) => {
  const [timeLeft, setTimeLeft] = useState(1);

  useEffect(() => {
    if (startTime?.startTime !== null) {
      setTimeLeft(Math.round(startTime?.startTime) / 1000);
    }
  }, [startTime]);

  useEffect(() => {
    const countupInterval = setInterval(() => {
      if (timeLeft > 1) {
        setTimeLeft(timeLeft - 1);
      }
    }, 1000);

    return () => clearInterval(countupInterval);
  }, [timeLeft]);
  const hours = Math.floor(timeLeft / 3600);
  const minutes = Math.floor((timeLeft % 3600) / 60);
  const secondsLeft = Math.floor(timeLeft % 60);

  useEffect(() => {
    if (Math.floor(timeLeft) === 0 && startTime) {
      if (startTime?.getData) {
        startTime?.getData(1, "");
      }
    }
  }, [timeLeft, startTime]);

  return (
    <>
      <p style={{ margin: "0px" }}>{`${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${secondsLeft.toString().padStart(2, "0")}`}</p>
    </>
  );
};

export default CountDown;
