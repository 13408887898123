import { useDispatch, useSelector } from "react-redux";
import { Logo } from "../../Assets/Logo";
import { Bitcoin } from "../../Assets/Navbar/Bitcoin";
import { Money } from "../../Assets/Navbar/Money";
import { Notification } from "../../Assets/Profile/Notification";
import Profile from "../../Assets/Profile/profile.png";
import { StyledNavlink } from "../../GlobalStyles";
import { StyledNavbar, StyledSmallScreen } from "./Navbar.styles";
import { Button } from "antd";
import { getUserAddress } from "../../Utils/Sats-connect/getAddress";
import { ROUTES } from "../../Constants/Routes";
import { toast } from "react-toastify";
import { setAddresses, setUserId } from "../../Services/globalslice";
import Loader from "../Loader/Loader";
import { useEffect, useState } from "react";
import { LendIcon } from "../../Assets/Navbar/LendIcon";
import { OfferIcon } from "../../Assets/Navbar/OfferIcon";
import { useLocation } from "react-router";
import { signWalletMessage } from "../../Utils/Sats-connect/signMessage";
import { useGetRoleQuery, useLoginMutation } from "../../Services/sloan";
import { ActiveIcon } from "../../Assets/ActiveIcon";
import { TiThMenu } from "react-icons/ti";
import { MdAdminPanelSettings } from "react-icons/md";
import { checkJwtExpiration } from "../../Utils/common";
// import NotificationPopUp from "../Modals/Notification/NotificationModal";
import TopHeadline from "../TopHeadline/TopHeadline";
import NotificationPopover from "../Modals/Notification/NotificationPopover";

const Navbar = () => {
  const location = useLocation();
  const [isLoad, setIsLoad] = useState(false);
  const addresses = useSelector((state) => state.global.addresses);
  const { data: getRole, refetch } = useGetRoleQuery({
    skip: false,
  });
  const dispatch = useDispatch();
  const [login] = useLoginMutation();
  const [show, setShow] = useState(false);
  const [token, setToken] = useState(null);
  // const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  // const closeNotification = () => {
  //   setIsNotificationOpen(false);
  // };

  const handleWalletConnect = async () => {
    try {
      setIsLoad(true);
      const addresses = await getUserAddress({ dispatch, setIsLoad });
      const ordinalAddress = addresses?.[0]?.address;
      const paymentAddress = addresses?.[1]?.address;
      const paymentPubkey = addresses?.[1]?.publicKey;
      const ordinalSignature = await signWalletMessage({
        address: ordinalAddress,
        message: "This is to verify the ordinal address",
      });
      const paymentSignature = await signWalletMessage({
        address: paymentAddress,
        message: "This is to verify the payments address",
      });

      const response = await login({
        ordinal_address: ordinalAddress,
        payment_address: paymentAddress,
        ordinal_address_signature: ordinalSignature,
        payment_address_signature: paymentSignature,
        payment_pubkey: paymentPubkey

      }).unwrap();

      localStorage.setItem("address", JSON.stringify(addresses));
      localStorage.setItem("token", response?.access_token);
      localStorage.setItem("userId", response?.user_id);
      dispatch(setAddresses(addresses));
      dispatch(setUserId(response?.user_id));
      setIsLoad(false);
      toast("Login Successful");
      await refetch();
      setToken(response?.access_token);
    } catch (error) {
      toast(error?.data?.message);
      setIsLoad(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (getRole) {
      localStorage.setItem("role", getRole?.role);
    }
  }, [getRole, token]);

  return (
    <nav>
      <TopHeadline />
      <StyledNavbar>
        {isLoad && <Loader />}
        {/* APPLICATION LOGO */}
        <StyledNavlink to={"/"}>
          <div className="logo-container">
            <Logo width={200} />
          </div>
        </StyledNavlink>

        <div className="hamburg-menu">
          {checkJwtExpiration() && getRole?.role === "admin" && (
            <StyledNavlink to={ROUTES.ADMIN} className="admin" title="Admin">
              <MdAdminPanelSettings
                style={{
                  height: "34px",
                  width: "34px",
                  color: location.pathname === ROUTES.ADMIN ? "#ff7f00" : "#fff",
                }}
              />
            </StyledNavlink>
          )}
          <NotificationPopover />
          <TiThMenu
            onClick={() => setShow(!show)}
            style={{ width: "50px", height: "50px", color: "#ff7f00" }}
          />
        </div>

        {/* NAVBAR LINKS */}
        <div className="navbar-links-container">
          <StyledNavlink to={ROUTES.LOAN}>
            <div className="link-container">
              <Bitcoin color={location.pathname === ROUTES.LOAN ? "#ff7f00" : "#fff"} />
              <span>Loans</span>
            </div>
          </StyledNavlink>
          <StyledNavlink to={ROUTES.BORROW}>
            <div className="link-container">
              <Money color={location.pathname === ROUTES.BORROW ? "#ff7f00" : "#fff"} />
              <span>Borrow</span>
            </div>
          </StyledNavlink>
          <StyledNavlink to={ROUTES.Lend}>
            <div className="link-container">
              <LendIcon color={location.pathname === ROUTES.Lend ? "#ff7f00" : "#fff"} />
              <span>Lend</span>
            </div>
          </StyledNavlink>
          <StyledNavlink to={ROUTES.OFFER}>
            <div className="link-container">
              <OfferIcon color={location.pathname === ROUTES.OFFER ? "#ff7f00" : "#fff"} />
              <span>Offers</span>
            </div>
          </StyledNavlink>
          <StyledNavlink to={ROUTES.ACTTIVE_LOANS}>
            <div className="link-container">
              <ActiveIcon color={location.pathname === ROUTES.ACTTIVE_LOANS ? "#ff7f00" : "#fff"} />
              <span>Active Loans</span>
            </div>
          </StyledNavlink>
        </div>
        {checkJwtExpiration() ? (
          //  { PROFILE INFO }
          <div className="profile-container">
            {/* <StyledDropdown
              menu={{
                items,
              }}
              placement="bottomRight"
              trigger={["click"]}
            >
              <a onClick={(e) => e.preventDefault()}> */}
            {getRole?.role === "admin" && (
              <StyledNavlink to={ROUTES.ADMIN} className="admin" title="Admin">
                <MdAdminPanelSettings
                  style={{
                    height: "34px",
                    width: "34px",
                    color: location.pathname === ROUTES.ADMIN ? "#ff7f00" : "#fff",
                  }}
                />
              </StyledNavlink>
            )}
            <NotificationPopover />
            <StyledNavlink to={ROUTES.PROFILE}>
              <img className="profile-img" src={Profile} alt="profile" />
            </StyledNavlink>
          </div>
        ) : (
          <Button onClick={handleWalletConnect} size="large" className="connect-btn">
            Connect Wallet
          </Button>
        )}
      </StyledNavbar>
      {show && (
        <StyledSmallScreen>
          {addresses?.length > 0 ? (
            <div className="small-menu">
              <StyledNavlink
                onClick={() => setShow(false)}
                to={ROUTES.LOAN}
                className="link-container"
              >
                <span style={{ color: location.pathname === ROUTES.LOAN ? "#ff7f00" : "#fff" }}>
                  Loans
                </span>
              </StyledNavlink>
              <StyledNavlink
                onClick={() => setShow(false)}
                to={ROUTES.BORROW}
                className="link-container"
              >
                <span style={{ color: location.pathname === ROUTES.BORROW ? "#ff7f00" : "#fff" }}>
                  Borrow
                </span>
              </StyledNavlink>
              <StyledNavlink
                onClick={() => setShow(false)}
                to={ROUTES.Lend}
                className="link-container"
              >
                <span style={{ color: location.pathname === ROUTES.Lend ? "#ff7f00" : "#fff" }}>
                  Lend
                </span>
              </StyledNavlink>
              <StyledNavlink
                onClick={() => setShow(false)}
                to={ROUTES.OFFER}
                className="link-container"
              >
                <span style={{ color: location.pathname === ROUTES.OFFER ? "#ff7f00" : "#fff" }}>
                  Offers
                </span>
              </StyledNavlink>
              <StyledNavlink
                onClick={() => setShow(false)}
                to={ROUTES.ACTTIVE_LOANS}
                className="link-container"
              >
                <span
                  style={{ color: location.pathname === ROUTES.ACTTIVE_LOANS ? "#ff7f00" : "#fff" }}
                >
                  Active Loans
                </span>
              </StyledNavlink>
              <StyledNavlink
                onClick={() => setShow(false)}
                to={ROUTES.PROFILE}
                className="link-container"
              >
                <span style={{ color: location.pathname === ROUTES.PROFILE ? "#f90" : "#fff" }}>
                  Profile
                </span>
              </StyledNavlink>
            </div>
          ) : (
            <Button onClick={handleWalletConnect} size="large" className="connect-btn">
              Connect Wallet
            </Button>
          )}
        </StyledSmallScreen>
      )}
      {/* <NotificationPopUp
        handleCancel={closeNotification}
        isModalOpen={isNotificationOpen}
        addresses={addresses}
      /> */}
    </nav>
  );
};

export default Navbar;
