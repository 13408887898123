export const Money = ({ color }) => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2433 28.8856C5.71571 28.8856 2.04858 32.5528 2.04858 37.0803C2.04858 41.6079 5.71571 45.275 10.2433 45.275C14.7709 45.275 18.438 41.6079 18.438 37.0803C18.438 32.5528 14.7709 28.8856 10.2433 28.8856ZM12.292 36.4247C12.292 37.6744 11.6159 38.8627 10.5506 39.4978L8.9936 40.4402C8.74775 40.604 8.46094 40.6655 8.19461 40.6655C7.68244 40.6655 7.17027 40.3992 6.88346 39.928C6.45324 39.2109 6.67859 38.2481 7.41611 37.8178L8.97311 36.8755C9.11652 36.7935 9.21895 36.6091 9.21895 36.4452V34.54C9.21895 33.6795 9.9155 32.983 10.7555 32.983C11.5954 32.983 12.292 33.6795 12.292 34.5195V36.4247Z"
        fill={color}
      />
      <path
        d="M34.8276 8.39886H14.3408C8.19476 8.39886 4.09741 11.4719 4.09741 18.6422V25.9355C4.09741 26.6935 4.87591 27.1647 5.55197 26.8574C7.55967 25.9355 9.87468 25.5873 12.3126 26.0175C17.7006 26.9804 21.6546 31.9791 21.5112 37.4491C21.4907 38.3095 21.3678 39.1495 21.1424 39.969C20.9785 40.604 21.4907 41.1982 22.1462 41.1982H34.8276C40.9736 41.1982 45.0709 38.1252 45.0709 30.9548V18.6422C45.0709 11.4719 40.9736 8.39886 34.8276 8.39886ZM24.5842 29.91C21.757 29.91 19.4625 27.6154 19.4625 24.7883C19.4625 21.9611 21.757 19.6666 24.5842 19.6666C27.4113 19.6666 29.7059 21.9611 29.7059 24.7883C29.7059 27.6154 27.4113 29.91 24.5842 29.91ZM39.4371 28.8856C39.4371 29.7256 38.7405 30.4221 37.9006 30.4221C37.0606 30.4221 36.3641 29.7256 36.3641 28.8856V20.6909C36.3641 19.851 37.0606 19.1544 37.9006 19.1544C38.7405 19.1544 39.4371 19.851 39.4371 20.6909V28.8856Z"
        fill={color}
      />
    </svg>
  );
};
