import styled from "styled-components"

export const StyledNotFound = styled.div`
display: flex;
width: 100%;
min-height: 60vh;
justify-content: center;
align-items: center;
  .err{
    font-size: 80px;
  }

`