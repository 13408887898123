import styled from "styled-components";

export const StyledFooter = styled.div`
  background: #ff7f00;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  padding: 20px;
  font-family: 'Avenir Bold';
  font-size: 18px;
  line-height: 125.8%; /* 30.192px */
  letter-spacing: 0.48px;
`;
