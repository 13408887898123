import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { config } from "./config";

export const MagicEdenBaseQuery = fetchBaseQuery({
  baseUrl: "https://api-mainnet.magiceden.dev",
  prepareHeaders: (headers, { getState }) => {
    headers.set('Authorization', "Bearer d4351a7c-67e1-4a57-885b-d0b230160fc1")

    return headers;
  },
});