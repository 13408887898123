export const OfferIcon = ({ color }) => {
  return (
    <svg width="50" height="50" viewBox="0 0 41 40" fill={color} xmlns="http://www.w3.org/2000/svg">
      <g id="Frame 6">
        {/* <rect width="40" height="40" transform="translate(0.5)" fill="white" /> */}
        <path id="Vector" d="M28.3301 1.79821L10.1603 7.83665C-2.05344 11.9227 -2.05344 18.5851 10.1603 22.6509L15.5529 24.4423L17.3437 29.8367C21.4083 42.0544 28.0886 42.0544 32.1532 29.8367L38.2098 11.6811C40.906 3.52923 36.4793 -0.919082 28.3301 1.79821ZM28.974 12.6271L21.3278 20.3161C21.026 20.618 20.6437 20.7589 20.2613 20.7589C19.879 20.7589 19.4967 20.618 19.1949 20.3161C18.6114 19.7324 18.6114 18.7662 19.1949 18.1825L26.8411 10.4936C27.4246 9.90984 28.3904 9.90984 28.974 10.4936C29.5575 11.0773 29.5575 12.0434 28.974 12.6271Z" fill={color} />
      </g>
    </svg>
  );
};
