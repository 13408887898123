import { getAddress } from "sats-connect";
import { config } from "../../config";
import { toast } from "react-toastify";

export const getUserAddress = async ({ dispatch, setIsLoad }) => {
  return new Promise(async (resolve, reject) => {
    const getAddressOptions = {
      payload: {
        purposes: ["ordinals", "payment"],
        message: "Address for login",
        network: {
          type: config.ENVIRONMENT,
        },
      },
      onFinish: (response) => {
        resolve(response?.addresses);
      },
      onCancel: () => {
        toast("Request canceled")
        setIsLoad(false)
      },
    };
    try {
      await getAddress(getAddressOptions);
    } catch (error) {
      console.log(error);
      reject(error); // Reject the promise in case of an error
    }
  });
};
