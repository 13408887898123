import { Button } from "antd";
import styled from "styled-components";

export const StyledLoanCard = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  border-bottom: 1px solid #fff;
  padding: 16px;
  &:hover {
    background: #ffffff30;
    border-color: #ff7f00;
  }

  .collection {
    display: flex;
    gap: 12px;
    align-items: center;
    /* justify-content: center; */
    .img {
      height: 80px;
      width: 80px;
      border-radius: 100%;
      border: 2px solid #fff;
      object-fit: cover;
    }
    .name {
      h2 {
        margin: 0;
        padding: 0;
        color: #fff;
        text-align: center;
        text-transform: uppercase;
        font-family: "Avenir Bold";
        font-size: 24px;
      }
      h3 {
        margin: 0;
        padding: 0;
        color: #707070;
        text-align: center;
        font-family: "Avenir Medium";
        font-size: 20px;
      }
    }
  }
  .offer,
  .borrowed,
  .repayment,
  .term {
    color: #fff;
    text-align: center;
    font-family: 'Avenir Medium';
    font-size: 26px;
    .unit {
      color: #fff;
      font-family: "Avenir Medium";
      font-size: 20px;
    }
    .percent {
      color: #ff7f00;
      text-align: center;
      font-family: 'Avenir Medium';
      font-size: 20px;
    }
  }
  .btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    .expand {
      display: flex;
      gap: 8px;
      align-items: center;
      border: 1px solid #fff;
      border-radius: 15px;
      padding: 16px 25px;
      color: #fff;
      font-family: 'Avenir Bold';
      font-size: 18px;
      text-transform: uppercase;
    }
    .revoke {
      border: none;
      border-radius: 15px;
      padding: 16px 50px;
      background-color: #ff7f00;
      color: #fff;
      font-family: "Avenir Bold";
      font-size: 18px;
      cursor: pointer;
      &:hover {
        color: #fff !important;
        background-color: #901fe9;
      }
    }
    .view {
      border: none;
      border-radius: 15px;
      padding: 16px 50px;
      background-color: #ff7f00;
      color: #fff;
      font-family: "Avenir Bold";
      font-size: 18px;
      cursor: pointer;
      &:hover {
        color: #fff !important;
        background-color: #901fe9;
      }
    }
  }
  .ant-progress .ant-progress-inner {
    background-color: black;
  }
  .ant-progress .ant-progress-bg {
    background-color: #ff7f00;
  }
  .ant-progress .ant-progress-outer {
    padding: 0px 32px !important;
  }
  @media (max-width: 1280px) {
    width: 1280px;
    padding: 0px;
  }
`;

export const CollectBtn = styled(Button)`
  border: none;
  border-radius: 15px;
  padding: 16px 50px;
  background-color: #ff7f00;
  color: #fff;
  font-family: 'Avenir Medium';
  font-size: 18px;
  border: none;
  height: 55px;
  cursor: pointer;
  &:hover {
    color: #fff !important;
    background-color: #901fe9;
  }
`;
