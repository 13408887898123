import { Navigate, Outlet } from "react-router";
import { ROUTES } from "../Constants/Routes";
import { checkJwtExpiration } from "../Utils/common";
import { useGetRoleQuery } from "../Services/sloan";

export const AdminRoute = () => {
  // const role = localStorage.getItem("role");
  const { data: getRole } = useGetRoleQuery(
    {
      skip: false
    }
  )
  console.log("herererererer")
  console.log("Role",getRole)
  return getRole?.role === "admin" && checkJwtExpiration() ? <Outlet /> : <Navigate to={ROUTES.HOME} />;
};
