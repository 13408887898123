import styled from "styled-components";
import { Dropdown } from "antd";

export const StyledNavbar = styled.div`
  box-sizing: border-box;
  padding: 50px;
  width: 100%;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .ant-btn {
    &:hover {
      color: #fff !important;
      background-color: #901fe9;
    }
  }
  .connect-btn {
    background-color: #ff7f00;
    box-shadow: none;
    border: none;
    height: 100%;
    border-radius: 20px;
    padding: 20px 20px;
    outline: none;
    span {
      color: #fff;
      font-family: "Avenir Bold";
      font-size: 20px;
      text-transform: uppercase;
      line-height: 125.8%;
      letter-spacing: 0.48px;
    }
  }
  .navbar-links-container {
    display: flex;
    align-items: center;
    gap: 80px;
    .link-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 10px;
      span {
        color: #fff;
        font-family: 'Avenir Bold';
        font-size: 24px;
        text-transform: uppercase;
        line-height: 125.8%;
        letter-spacing: 0.48px;
      }
    }
  }
  .profile-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    .notification-icon {
      cursor: pointer;
    }
    .admin {
      cursor: pointer;
      color: #ff7f00;
    }
    .profile-img {
      height: 65px;
      width: 65px;
      border-radius: 100%;
      border: 2px solid #6c6c6c;
    }
  }
  .hamburg-menu {
    cursor: pointer;
    display: none;
  }

  @media (max-width: 1200px) {
    /* border-bottom: none; */
    /* box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.25); */
    .navbar-links-container,
    .profile-container {
      display: none;
    }
    .hamburg-menu {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }
  @media (max-width: 1160px) {
    .connect-btn {
      display: none;
    }
  }
`;

export const StyledSmallScreen = styled.div`
  /* padding: 20px; */
  /* position: fixed; */
  /* top: 150px; */
  width: 100%;
  /* z-index: 50; */
  /* background: #fff; */
  border-bottom: 1px solid #fff;
  /* box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.25); */
  align-items: center;
  justify-content: center;
  display: none;
  .connect-btn {
    background-color: #ff7f00;
    box-shadow: none;
    border: none;
    height: 100%;
    border-radius: 0px;
    padding: 20px 40px;
    outline: none;
    span {
      color: #fff;
      font-family: "Avenir Bold";
      font-size: 20px;
      text-transform: uppercase;
      font-weight: 900;
      line-height: 125.8%;
      letter-spacing: 0.48px;
    }
  }
  .small-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin-bottom: 20px;
    .link-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      span {
        font-family: "Avenir Bold";
        font-size: 24px;
        text-transform: uppercase;
        font-weight: 900;
        line-height: 125.8%;
        letter-spacing: 0.48px;
      }
    }
  }

  @media (max-width: 1200px) {
    display: flex;
  }
`;

export const StyledDropdown = styled(Dropdown)``;
