import React from "react";
import { useSelector } from "react-redux";
import { copyToClipboard, truncateAddress } from "../../../Utils/common";
import { StyledExtendPopUp } from "./ExtendModal.styles";
import { Cancel } from "../../../Assets/Cancel";
import moment from "moment";

const ExtendPopUp = ({ isModalOpen, handleOk, handleCancel, item, loan, data }) => {
  return (
    <StyledExtendPopUp
      footer={null}
      closeIcon={false}
      title=""
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <div className="header">
        <span className="cancel-btn" onClick={handleCancel}>
          <Cancel />
        </span>
      </div>
      <div className="upper">
        <div className="image">
          <img
            src={item?.collection_data?.[0]?.image_uri || item?.collection_info?.image_uri}
            alt=""
          />
          <h3>
            {item?.collection_data?.[0]?.collection_name || item?.collection_info?.collection_name}
          </h3>
        </div>
      </div>
      <div className="lower">
        <div className="data">
          <div className="sub-data">
            <h3>apy</h3>
            <p>{item?.collection_data?.[0]?.apy || item?.collection_info?.apy}</p>
          </div>
          <div className="sub-data">
            <h3>duration</h3>
            <p>
              {" "}
              {item?.collection_data?.[0]?.loan_duration_in_days ||
                item?.collection_info?.loan_duration_in_days}{" "}
              D
            </p>
          </div>
          {/* <div className="sub-data">
            <h3>floor</h3>
            <p>0.13</p>
          </div> */}
        </div>
        {/* <div className="ago">
          uploaded 10 days ago
        </div> */}
        <div className="status">
          <h2>Start Date</h2>
          <p>{moment(loan ? loan?.start_date : data?.loan_start_date).format("DD-MM-YYYY")}</p>
        </div>
        <div className="status">
          <h2>End Date</h2>
          <p>{moment(loan ? loan?.end_date : data?.loan_end_date).format("DD-MM-YYYY")}</p>
        </div>
        <div className="status">
          <h2>status</h2>
          {loan ? (
            <p>
              {loan?.collection_state === "completed" && loan?.repayment === "completed"
                ? "Completed"
                : loan?.repayment === "failed"
                ? "Expired"
                : loan?.wallet_repayment === "true"
                ? "Repaid"
                : "Pending"}
            </p>
          ) : (
            <p>Completed</p>
          )}
        </div>
        <div className="status ">
          <h2>inscription id</h2>
          <p
            title={loan?.inscription_id || data?.inscription_id}
            onClick={() => copyToClipboard(loan?.inscription_id || data?.inscription_id)}
          >
            {truncateAddress(loan?.inscription_id || data?.inscription_id)}
          </p>
        </div>
        <div className="status ">
          <h2>Lender Address</h2>
          <p
            title={loan?.offer_details?.lender_address || data?.lender_address}
            onClick={() =>
              copyToClipboard(loan?.offer_details?.lender_address || data?.lender_address)
            }
          >
            {truncateAddress(loan?.offer_details?.lender_address || data?.lender_address)}
          </p>
        </div>
        {!data && (
          <div className="status">
            <h2>offer amount</h2>
            <p>
              {(Number(loan?.offer_details?.offer_amount || data?.offer_amount) / 10 ** 8).toFixed(
                8
              )}
            </p>
          </div>
        )}
        {!data && (
          <div className="interest">
            {(Number(loan?.offer_details?.interest || data?.interest) / 10 ** 8).toFixed(8)} BTC
            interest
          </div>
        )}
      </div>
    </StyledExtendPopUp>
  );
};

export default ExtendPopUp;
