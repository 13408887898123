import styled from "styled-components"

export const StyledLoading = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  z-index: 100;
  background: rgba(10,10,10,.1);
  backdrop-filter: blur(10px);

`