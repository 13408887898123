import { toast } from "react-toastify";
import { setAddresses, setUserId } from "../Services/globalslice";
import { isJwtExpired } from "jwt-check-expiration";
import { config } from "../config";

export const checkIfLogin = ({ dispatch }) => {
  const addresses = JSON.parse(localStorage.getItem("address"));
  const id = localStorage.getItem("userId");

  if (addresses?.length > 0) {
    dispatch(setAddresses(addresses));
    dispatch(setUserId(id));
  }
};

export const truncateAddress = (address = "") => {
  return address?.slice(0, 6) + "..." + address?.slice(address?.length - 5);
};

export const cleanedString = (str) => {
  let clean_str = str.replaceAll('"', "");
  return clean_str;
};

export const copyToClipboard = (text = "") => {
  navigator.clipboard.writeText(text);
  toast("Copy to clipboard");
};

export const checkJwtExpiration = () => {
  try {
    const token = localStorage.getItem("token");
    return !!token && !isJwtExpired(token);
  } catch (error) {
    return false;
  }
};

function hexStringToUint8Array(hexString) {
  if (hexString.length % 2 !== 0) throw new Error("Invalid hexString");
  console.log("here")
  var arrayBuffer = new Uint8Array(hexString.length / 2);

  for (var i = 0; i < hexString.length; i += 2) {
    var byteValue = parseInt(hexString.substr(i, 2), 16);
    arrayBuffer[i / 2] = byteValue;
  }

  console.log("here2");

  return arrayBuffer;
}

export async function decryptMessage(encryptedHex) {
  console.log("hhehrhere")
  console.log(encryptedHex.length)
  const encryptedBytes = hexStringToUint8Array(encryptedHex);
  console.log(config.ENCRYPTION_IV)
  const keyBytes = hexStringToUint8Array(config.ENCRYPTION_KEY);
  const ivBytes = hexStringToUint8Array(config.ENCRYPTION_IV);

     
   console.log("decrypt message",encryptedBytes,keyBytes,ivBytes);
  const key = await crypto.subtle.importKey("raw", keyBytes, { name: "AES-CBC" }, false, [
    "decrypt",
  ]);

  const decrypted = await crypto.subtle.decrypt(
    { name: "AES-CBC", iv: ivBytes },
    key,
    encryptedBytes
  );

  return new TextDecoder().decode(new Uint8Array(decrypted));
}

export function supportsHEVCAlpha() {
  const navigator = window.navigator;
  const ua = navigator.userAgent.toLowerCase();
  const hasMediaCapabilities = !!(
    navigator.mediaCapabilities && navigator.mediaCapabilities.decodingInfo
  );
  const isSafari =
    ua.indexOf("safari") != -1 && !(ua.indexOf("chrome") != -1) && ua.indexOf("version/") != -1;

  return isSafari && hasMediaCapabilities;
}
