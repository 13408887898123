export const ROUTES = {
  HOME: "/",
  BORROW: "/borrow",
  LISTINGS: "/listings",
  Lend: "/lend",
  PROFILE: "/profile",
  LOAN: "/loan",
  OFFER: "/offer",
  ACTTIVE_LOANS: "/active-loans",
  ADMIN: "/admin"
};
