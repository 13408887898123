import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define a service using a base URL and expected endpoints
export const cmsApi = createApi({
  reducerPath: "cmsApi",
  baseQuery: fetchBaseQuery({ baseUrl: "https://block-lenders-cms-f8efdcaa1b28.herokuapp.com" }),
  endpoints: (builder) => ({
    getCmsData: builder.query({
      query: (data) => {
        return {
          url: `/api/block-lender`,
          method: "GET",
        };
      },
    }),
  }),
});

export const { useGetCmsDataQuery } = cmsApi;