import React, { useState } from "react";
import { StyledOfferCard } from "./OfferCard.styles";
import pfp from "../../Assets/Images/pfp.png";
import { IoIosArrowDown } from "react-icons/io";
import RevokeModal from "../Modals/RevokeModal/RevokeModal";
import { CollectBtn } from "../LoanCard/LoanCard.styles";
import {
  useCollectOffersMutation,
  useExecuteSignatureWithWifMutation,
  useUpdateCollMutation,
} from "../../Services/sloan";
import { useSelector } from "react-redux";
import { signWalletTransaction } from "../../Utils/Sats-connect/SignTransaction";
import { config } from "../../config";
import { cleanedString } from "../../Utils/common";
import ExtendPopUp from "../Modals/ExtendModal/ExtendModal";
import CountDown from "../CountDown/CountDown";
import moment from "moment";
import ms from "ms";
import CountDownRange from "../CountDownRange/CountDownRange";

const OfferCard = (props) => {
  const addresses = useSelector((state) => state.global.addresses);
  const [isRevokePopUpOpen, setIsRevokePopUpOpen] = useState(false);
  const [collectOffers, { isLoading: collectOffersLoading }] = useCollectOffersMutation();
  const [updateColl] = useUpdateCollMutation();
  const [executeSignatureWithWif] = useExecuteSignatureWithWifMutation();
  const [isExtendPopUpOpen, setIsExtendPopUpOpen] = useState(false);

  const closeExtendPopUp = () => {
    setIsExtendPopUpOpen(false);
  };
  const closeRevokePopUp = () => {
    setIsRevokePopUpOpen(false);
  };

  const handleOffersCollect = async () => {
    try {
      const collectOffersData = {
        pubkey: addresses?.[1]?.publicKey,
        address: addresses?.[1]?.address,
        loan_escrow_id: props?.data?.loan_escrow_id,
      };

      const offersResponse = await collectOffers(collectOffersData).unwrap();
      const newArr = offersResponse?.data?.executeEscrow?.sides
        .filter((item) => item?.movement === "input")
        .map((item, i) => {
          return {
            address: item?.leaf,
            signingIndexes: [i],
          };
        });
      const finalArr = newArr.filter((item) => item?.address !== null);

      const txn = await signWalletTransaction({
        psbtBase64: offersResponse?.data?.executeEscrow?.transactions[0]?.base64,
        // address: addresses[1]?.address,
        // index: 1,
        inputsToSign: finalArr,
        network: config.ENVIRONMENT,
      });

      const executeSignatureData = {
        final_signed_txn: txn,
        escrow_id: cleanedString(offersResponse?.message),
        wif: props?.data?.wif,
      };

      await executeSignatureWithWif(executeSignatureData).unwrap();

      const updateRes = updateColl({
        offer_id: props?.data?.offer_id,
      }).unwrap();
      console.log(updateRes);
    } catch (err) {
      console.log(err);
    }
  };

  const renderTerm = () => {
    const loan = props?.view ? props?.data : props?.item?.offers[props?.item?.offers.length - 1];
    const currentTime = Date.now();
    const startTime = new Date(loan.loan_start_date).getTime();
    const endTime = new Date(loan.loan_end_date).getTime();

    if (loan.endTime < currentTime) {
      return "Completed";
    }

    if (moment(endTime).diff(moment()) > ms("24h")) {
      return <CountDownRange startTime={startTime} endTime={endTime} />;
    } else {
      return <CountDown startTime={endTime - currentTime} />;
    }
  };

  return (
    <StyledOfferCard>
      <div className="collection">
        <img
          className="img"
          src={
            props?.item?.collection_info?.image_uri ? props?.item?.collection_info?.image_uri : pfp
          }
          alt=""
        />
        <div className="name">
          <h2>{props?.item?.collection_info?.collection_name}</h2>
          {/* <h3>NFT #1234</h3> */}
        </div>
      </div>
      <div className="offer">
        {props?.revoke
          ? (Number(props?.data?.offer_amount) / 10 ** 8).toFixed(8)
          : (Number(props?.item?.offers[0].offer_amount) / 10 ** 8).toFixed(8)}
      </div>
      <div className="interest">
        {props?.revoke
          ? (Number(props?.data?.interest) / 10 ** 8).toFixed(8)
          : (Number(props?.item?.offers[0].interest) / 10 ** 8).toFixed(8)}
      </div>
      <div className="apy">{props?.item?.collection_info?.apy}%</div>
      <div className="status">
        {!props?.active ? (
          <span>
            {props?.data?.collection_state === "completed" ? (
              <span>Collected</span>
            ) : props?.data?.offer_state === "completed" &&
              props?.data?.repayment === "failed" &&
              props?.data?.confirmation !== "true" ? (
              <span>Awaiting Confirmation</span>
            ) : props?.data?.offer_state === "completed" &&
              props?.data?.repayment === "failed" &&
              props?.data?.confirmation === "true" ? (
              <span>Awaiting Collection</span>
            ) : props?.data?.offer_state === "completed" &&
              props?.data?.repayment === "completed" ? (
              <span>Completed</span>
            ) : (
              <span>Seeking Borrowers</span>
            )}{" "}
          </span>
        ) : (
          <div>
            {renderTerm()}
            {/* <Progress percent={props?.view ? (((moment(props?.data?.loan_end_date) - moment()) / 86400000).toFixed(0) / props?.item?.collection_info?.loan_duration_in_days) * 100 : (((moment(props?.item?.offers[0]?.loan_end_date) - moment()) / 86400000).toFixed(0) / props?.item?.collection_info?.loan_duration_in_days) * 100} showInfo={false} /> */}
          </div>
        )}
      </div>
      <div className="btn">
        {!props?.revoke && !props?.view && (
          <div className="expand">
            <span>Expand</span>
            <IoIosArrowDown />
          </div>
        )}
        {!props?.active && props?.revoke && props?.data?.offer_state === "open" && (
          <button
            onClick={() => {
              setIsRevokePopUpOpen(true);
            }}
            className="revoke"
          >
            Revoke
          </button>
        )}
        {!props?.active &&
          props?.revoke &&
          props?.data?.collection_state !== "completed" &&
          props?.data?.repayment === "failed" &&
          props?.data?.offer_state === "completed" && (
            <CollectBtn
              disabled={props?.data?.confirmation !== "true"}
              loading={collectOffersLoading}
              onClick={handleOffersCollect}
            >
              Collect
            </CollectBtn>
          )}
        {props?.active && props?.view && (
          <button
            className="view"
            onClick={() => {
              setIsRevokePopUpOpen(true);
            }}
          >
            View
          </button>
        )}
        {!props?.active && props?.revoke && props?.data?.collection_state === "completed" && (
          <div
            className="view"
            onClick={() => {
              setIsExtendPopUpOpen(true);
            }}
          >
            View
          </div>
        )}
        {!props?.active &&
          props?.revoke &&
          props?.data?.offer_state === "completed" &&
          props?.data?.repayment === "completed" && (
            <div
              className="view"
              onClick={() => {
                setIsExtendPopUpOpen(true);
              }}
            >
              View
            </div>
          )}
      </div>
      <RevokeModal
        item={props?.item}
        data={props?.data}
        view={props?.view}
        handleCancel={closeRevokePopUp}
        isModalOpen={isRevokePopUpOpen}
      />
      <ExtendPopUp
        item={props?.item}
        data={props?.data}
        handleCancel={closeExtendPopUp}
        isModalOpen={isExtendPopUpOpen}
      />
    </StyledOfferCard>
  );
};

export default OfferCard;
