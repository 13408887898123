import { styled } from "styled-components";

export const StyledPagination = styled.div`
  margin-top: 30px;
  .ant-pagination {
    text-align: center;
    .ant-pagination-item {
      border: 2px solid #fff;
      a {
        color: #fff;
        font-weight: 600;
      }
    }
    .ant-pagination-item-active {
      background-color: #ff7f00;
      border: none;
      a {
        color: #fff;
      }
    }
  }
`;
