import { NavLink } from "react-router-dom";
import { styled } from "styled-components";

export const StyledNavlink = styled(NavLink)`
  text-decoration: none;
  &.active {
    .link-container {
      span {
        color: #ff7f00 !important;
      }
    }
  }
`;

export const StyledSearchInput = styled.input`
  padding: 15px 20px;
  border-radius: 82px;
  border: 1px solid #fff;
  outline: none;
  background: transparent;
  color: #fff;
  font-family: "Avenir Medium";
  width: ${(props) => (props.small ? "500px" : "700px")};
  font-size: ${(props) => (props.small ? "20px" : "26px")};

  &::placeholder {
    color: #fff;
  }

  @media (max-width: 880px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    padding: 10px;
  }
`;
