import React from "react";
import { useForm } from "antd/es/form/Form";
import { Form, Input } from "antd";
import { useWhiteListUserMutation } from "../../../Services/sloan";
import { toast } from "react-toastify";
import { StyledBtn, StyledWhiteListPopUp } from "./WhiteList.styles";

const WhiteListPopUp = ({ isModalOpen, handleOk, handleCancel, addresses }) => {
  const [whiteList, { isLoading }] = useWhiteListUserMutation();
  const [addressForm] = useForm();

  const handleAddress = async (value) => {
    try {
      const res = await whiteList({ user_payment_address: value?.address }).unwrap();
      toast(res?.message);
      handleCancel();
      addressForm.resetFields();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <StyledWhiteListPopUp
      footer={null}
      closeIcon={false}
      title=""
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <h1>Add to whitelist</h1>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
        labore et dolore magna
      </p>
      <Form form={addressForm} onFinish={handleAddress} className="form" name="emailForm">
        <Form.Item
          name="address"
          rules={[
            {
              required: true,
              message: "Please enter address",
            },
          ]}
        >
          <Input placeholder="Enter address" className="modal-input" />
        </Form.Item>

        <StyledBtn loading={isLoading} htmlType="submit">
          Whitelist
        </StyledBtn>
      </Form>
    </StyledWhiteListPopUp>
  );
};

export default WhiteListPopUp;
