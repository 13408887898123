export const Bitcoin = ({ color }) => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M44.0835 29.1717C43.2977 29.1717 42.646 29.8233 42.646 30.6092C42.646 36.2825 38.6977 41.0358 33.3885 42.2817L33.906 41.4192C34.3085 40.7292 34.0977 39.8475 33.4077 39.445C32.7368 39.0425 31.836 39.2533 31.4335 39.9433L29.421 43.2975C29.1527 43.7383 29.1527 44.2942 29.4018 44.735C29.651 45.195 30.1302 45.4633 30.6477 45.4633C38.8318 45.4633 45.5018 38.7933 45.5018 30.6092C45.521 29.8233 44.8693 29.1717 44.0835 29.1717Z"
          fill={color}
        />
        <path
          d="M15.3334 0.421661C7.14925 0.421661 0.479248 7.09166 0.479248 15.2758C0.479248 16.0617 1.13091 16.7133 1.91675 16.7133C2.70258 16.7133 3.35425 16.0617 3.35425 15.2758C3.35425 9.60249 7.30258 4.84916 12.6117 3.60333L12.0942 4.46583C11.6917 5.15583 11.9026 6.03749 12.5926 6.43999C13.2634 6.84249 14.1642 6.63166 14.5667 5.94166L16.5792 2.58749C16.8284 2.14666 16.8476 1.60999 16.5792 1.14999C16.3301 0.709161 15.8509 0.421661 15.3334 0.421661Z"
          fill={color}
        />
        <path
          d="M41.2083 16.6175C41.2083 22.5208 36.915 27.3892 31.2991 28.29C31.2991 28.2708 31.2991 28.2708 31.2991 28.2517C30.7433 21.1025 24.955 15.2758 17.71 14.7008C18.6108 9.08499 23.4791 4.79166 29.3825 4.79166C35.9183 4.79166 41.2083 10.0817 41.2083 16.6175Z"
          fill={color}
        />
        <path
          d="M18.8024 27.2167C18.8024 26.9292 18.4766 26.4883 18.0741 26.4883H14.1257V27.9258H18.0741C18.4766 27.945 18.8024 27.6192 18.8024 27.2167Z"
          fill={color}
        />
        <path
          d="M18.8407 30.82H18.0741H14.1257V32.2575H18.8599C19.4732 32.2575 19.8949 31.8742 19.8949 31.5292C19.8949 31.1842 19.4541 30.82 18.8407 30.82Z"
          fill={color}
        />
        <path
          d="M28.4242 28.4625C27.9834 22.655 23.3451 18.0167 17.5376 17.5758C17.2309 17.5567 16.9434 17.5375 16.6176 17.5375C10.0817 17.5375 4.79175 22.8275 4.79175 29.3825C4.79175 35.9183 10.0817 41.2083 16.6176 41.2083C23.1534 41.2083 28.4626 35.9183 28.4626 29.3825C28.4626 29.0567 28.4434 28.7692 28.4242 28.4625ZM18.8409 35.1325H17.5759V35.8608C17.5759 36.6467 16.9242 37.2983 16.1384 37.2983C15.3526 37.2983 14.7009 36.6467 14.7009 35.8608V35.1325H12.6884C11.9026 35.1325 11.2509 34.4808 11.2509 33.695V29.3825V25.07C11.2509 24.2842 11.9026 23.6325 12.6884 23.6325H14.7009V22.9042C14.7009 22.1183 15.3526 21.4667 16.1384 21.4667C16.9242 21.4667 17.5759 22.1183 17.5759 22.9042V23.6325H18.0742C19.9909 23.6325 21.6776 25.3192 21.6776 27.2358C21.6776 27.7917 21.5434 28.3092 21.3134 28.7883C22.1951 29.44 22.7509 30.4367 22.7509 31.5483C22.7509 33.5225 21.0067 35.1325 18.8409 35.1325Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
