import styled from "styled-components";

export const StyledTop = styled.div`
  font-family: "Avenir Medium";
  background-color: #ff7f00;
  text-align: center;
  padding: 6px;
  color: white;
  z-index: 50;
  font-size: 20px;
  font-weight: 500;
`;
